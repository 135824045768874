import React, { useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';

const ContactUs = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window?.scrollTo(0, 0);
    }
  }, []);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_ubuz9wr', 'template_r31r6og', e.target, 'Xds_B1Rd1DvTXMAFC')
      .then(
        (response) => {
          console.log('SUCCESS!', response.text);
          alert('Votre message à été envoyé avec succés');
        },
        (error) => {
          console.log('FAILED...', error);
        },
      );
  };

  return (
    <div style={{ marginTop: '-40px' }}>
      <div className="bg" style={{ backgroundImage: `url("/assets/images/travel.jpg")` }}>
        <h4 className="bgTitle">Contact</h4>
      </div>
      <form
        onSubmit={sendEmail}
        className="cards vh-20 d-flex flex-column justify-content-center align-items-center"
      >
        <img
          src="/assets/svg/logos/logo.svg"
          alt="KeplerLogo"
          width="258px"
          style={{ marginBottom: '-50px' }}
        />
        <h2 className="card-header" style={{ color: '#293789' }}>
          Contactez Nous
        </h2>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ color: '#000' }}>Votre nom</label>
          <input
            className="Input"
            type="text"
            name="user_lastname"
            placeholder="Veuillez saisir votre nom ..."
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ color: '#000' }}>Votre prénom</label>
          <input
            className="Input"
            type="text"
            name="user_firstname"
            placeholder="Veuillez saisir votre prénom ..."
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ color: '#000' }}>Votre téléphone</label>
          <input
            className="Input"
            type="text"
            name="phone"
            placeholder="Veuillez saisir votre téléphone ..."
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ color: '#000' }}>Votre email</label>
          <input
            className="Input"
            type="email"
            name="user_email"
            placeholder="Veuillez saisir votre email ..."
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ color: '#000' }}>Message </label>
          <textarea
            name="message"
            rows={5}
            cols={53}
            className="content"
            placeholder="Votre message ..."
          />
        </div>
        <div className="g-recaptcha" data-sitekey="6Lc80DIqAAAAALyyDTrQ7kA4siQsLGE8wWilulZt" />
        <input type="submit" value="Send" className="sendBtn" />
      </form>
    </div>
  );
};

export default ContactUs;
